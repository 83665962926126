<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Neraca Perbandingan Periode</h4>
          </div>

          <!-- <button class="btn-add" @click="generatePdf">Export</button>
          <button
            class="btn-add"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            + Tambah
          </button> -->
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center justify-content-end">
            <button
              class="btn btn-save"
              :style="{ minWidth: '170px' }"
              @click="this.showModalRangeDouble = true"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.5082 2.125C12.3311 2.125 12.1469 2.19583 12.0124 2.33042L10.7161 3.62667L13.3724 6.28292L14.6686 4.98667C14.9449 4.71042 14.9449 4.26417 14.6686 3.98792L13.0111 2.33042C12.8694 2.18875 12.6924 2.125 12.5082 2.125ZM9.95819 6.38917L10.6099 7.04083L4.19236 13.4583H3.54069V12.8067L9.95819 6.38917ZM2.12402 12.2188L9.95819 4.38458L12.6144 7.04083L4.78027 14.875H2.12402V12.2188Z"
                  fill="#fff"
                />
              </svg>
              Ubah Parameter
            </button>
          </div>

          <iframe id="pdfFrame" v-if="!isLoading"></iframe>
          <div
            v-else
            :style="{
              height: '450px',
              background: '#8f8f8f',
              position: 'relative',
            }"
          >
            <loading
              :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }"
              :active="isLoading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              color="#68caf8"
              height="80"
              width="80"
            ></loading>
          </div>
        </div>
      </section>
    </div>
  </div>
  <modalRange
    :onPage="true"
    :show="showModalRangeDouble"
    :type="'profit_periode'"
    :isMerge="true"
    @hide="setHide()"
    @update="updateData($event)"
    :departement="optionDepartement"
    :parameter="parameterReport"
    :isOther="true"
    :isDivisi="true"
  ></modalRange>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import modalRange from "../../components/report/modalRange.vue";
// import Select2 from "vue3-select2-component";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "../../../../assets/font/poppins-bold.js";
import "../../../../assets/font/poppins-regular.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Loading from "vue3-loading-overlay";

import $ from "jquery";
import { get_ReportNeracaPeriode } from "../../../../actions/report/neraca";
import { cksClient } from "../../../../helper";
import moment from "moment";
import "moment/locale/id";
import { get_ListDivisi } from "../../../../actions/master";
import { DATA } from "../../../../constans/Url";
// import { NONE } from "apisauce";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    // Select2,
    Loading,
    modalRange,
  },
  data() {
    return {
      showModalRangeDouble: false,
      fullPage: true,
      isLoading: true,
      thr: "thr",
      bonus: "bonus",
      gaji: "gaji",
      canvas: null,
      optionDepartement: [],
      dataEstimatedAccount: [],
      parameterReport: {
        id_divisi: this.$route.query.id_divisi,
        date_start: this.$route.query.date_start,
        date_end: this.$route.query.date_end,
        merge: this.$route.query.merge,
        isAll: this.$route.query.isAll,
      },
      dataCompany: {
        nama: "",
        divisi: "",
        alamat: "",
        email: "",
        no_telp: "",
        logo: "",
      },
      dataReport: [],
      dataColumn: [],
      titleDate: "",
      titleDateCol: "",
      namaReport: "",
    };
  },

  mounted() {
    if (this.parameterReport.date_start && this.parameterReport.date_end) {
      this.getData();
    }
    // this.tooltip();
    // this.exportPdf();
  },

  created() {
    this.getDivisi();
  },

  methods: {
    getData() {
      var data = {
        ...this.parameterReport,
      };
      data.id_company = cksClient().get("_account").id_company;
      this.isLoading = true;
      get_ReportNeracaPeriode(
        data,
        async (res) => {
          this.dataColumn = [
            {
              title: "Deskripsi",
              dataKey: "akun",
            },
            {
              title: moment(res.first_periode).format("DD MMMM YYYY"),
              dataKey: "first",
            },
            {
              title: moment(res.second_periode).format("DD MMMM YYYY"),
              dataKey: "second",
            },
            {
              title: "Variance",
              dataKey: "nilai",
            },
          ];

          const {
            kas,
            biaya_dimuka,
            aset_lancar,
            aset_tetap,
            aset_tetap_tidak_lancar,
            aset_lainnya,
            akumulasi_penyusutan,
            persediaan,
            piutang_usaha,
            piutang_lainnya,
            kewajiban_jangka_pendek,
            kewajiban_jangka_panjang,
            hutang_usaha,
            ekuitas,
            divisi,
            company,
            // first_periode,
            // second_periode,
          } = res;
          this.dataCompany = company;
          this.dataCompany.divisi = divisi;
          if (divisi) {
            this.namaReport = divisi;
          } else {
            this.namaReport = cksClient().get("_account").nama_company;
          }

          this.dataReport = [];

          var data = [];
          data.push(
            {
              akun: "ASET",
              padding: 0,
              nilai: "",
              bold: true,
            },
            {
              akun: "ASET LANCAR",
              padding: 2,
              bold: true,
              nilai: "",
            },
            {
              akun: "Kas dan Setara Kas",
              padding: 3,
              bold: true,
              nilai: "",
            }
          );

          var yearArray = ["first", "second"];
          var jumlah_kas = 0;
          for (const key in kas) {
            if (Object.hasOwnProperty.call(kas, key)) {
              var element = kas[key];
              element.padding = 4 + parseInt(element.sub_level);
              var nilai = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el == "first") {
                    nilai += element[el] ? parseInt(element[el]) : 0;
                  } else {
                    nilai -= element[el] ? parseInt(element[el]) : 0;
                  }
                  element[el] = element[el] ? this.formatMoney(element[el]) : 0;
                }
              }
              element.nilai = this.formatMoney(nilai);
              if (element.sub_level === 1 || element.sub_level === "1") {
                jumlah_kas += element.nilai
                  ? parseInt(element.nilai.toString().split(".").join(""))
                  : 0;
              }
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (element.sub_level == "1" || element.sub_level == 1)
              ) {
                // element.color = "#FF3D3D";
              }
              data.push(element);
            }
          }
          var arryJmlKas = await this.countJumlahPendapatan(kas);
          data.push(
            {
              akun: "Jumlah Kas dan Setara Kas",
              bold: true,
              padding: 3,
              nilai: this.formatMoney(parseInt(jumlah_kas)),
              ...arryJmlKas,
            },
            {
              nama: "",
              padding: 0,
              nilai: "",
            },
            {
              akun: "Biaya Dibayar Dimuka",
              padding: 3,
              bold: true,
              nilai: "",
            }
          );

          var jumlah_biaya_dimuka = 0;
          for (const key in biaya_dimuka) {
            if (Object.hasOwnProperty.call(biaya_dimuka, key)) {
              var elbiaya = biaya_dimuka[key];
              elbiaya.padding = 4 + parseInt(elbiaya.sub_level);
              var nilaibiaya = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaibiaya += elbiaya[el] ? parseInt(elbiaya[el]) : 0;
                  } else {
                    nilaibiaya -= elbiaya[el] ? parseInt(elbiaya[el]) : 0;
                  }
                  elbiaya[el] = elbiaya[el] ? this.formatMoney(elbiaya[el]) : 0;
                }
              }
              if (elbiaya.sub_level === 1 || elbiaya.sub_level === "1") {
                jumlah_biaya_dimuka += nilaibiaya
                  ? parseInt(nilaibiaya.toString().split(".").join(""))
                  : 0;
              }
              elbiaya.nilai = this.formatMoney(nilaibiaya);
              elbiaya.nilai = elbiaya.nilai
                ? this.formatMoney(elbiaya.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elbiaya.sub_level == "1" || elbiaya.sub_level == 1)
              ) {
                elbiaya.color = "#FF3D3D";
              }
              data.push(elbiaya);
            }
          }

          var arryJmlBiayaDimuka = await this.countJumlahPendapatan(
            biaya_dimuka
          );

          data.push(
            {
              akun: "Jumlah Biaya Dibayar Dimuka",
              padding: 3,
              bold: true,
              nilai: this.formatMoney(parseInt(jumlah_biaya_dimuka)),
              ...arryJmlBiayaDimuka,
            },
            {
              akun: "",
              padding: 0,
              nilai: "",
            },
            {
              akun: "Piutang Usaha",
              bold: true,
              padding: 3,
              nilai: "",
            }
          );

          var jumlah_piutang_usaha = 0;
          for (const key in piutang_usaha) {
            if (Object.hasOwnProperty.call(piutang_usaha, key)) {
              var elbeban = piutang_usaha[key];
              elbeban.padding = 4 + parseInt(elbeban.sub_level);
              var nilaibeban = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaibeban += elbeban[el] ? parseInt(elbeban[el]) : 0;
                  } else {
                    nilaibeban -= elbeban[el] ? parseInt(elbeban[el]) : 0;
                  }
                  elbeban[el] = elbeban[el] ? this.formatMoney(elbeban[el]) : 0;
                }
              }
              if (elbeban.sub_level === 1 || elbeban.sub_level === "1") {
                jumlah_piutang_usaha += nilaibeban
                  ? parseInt(nilaibeban.toString().split(".").join(""))
                  : 0;
              }
              elbeban.nilai = this.formatMoney(nilaibeban);
              elbeban.nilai = elbeban.nilai
                ? this.formatMoney(elbeban.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elbeban.sub_level == "1" || elbeban.sub_level == 1)
              ) {
                elbeban.color = "#FF3D3D";
              }
              data.push(elbeban);
            }
          }

          var arryJmlPiutangUsaha = await this.countJumlahPendapatan(
            piutang_usaha
          );

          data.push(
            {
              akun: "Jumlah Piutang Usaha",
              padding: 3,
              bold: true,
              nilai: this.formatMoney(parseInt(jumlah_piutang_usaha)),
              ...arryJmlPiutangUsaha,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
            },
            {
              akun: "Piutang Diluar Usaha",
              padding: 3,
              bold: true,
              nilai: "",
            }
          );

          var jumlah_piutang_lainnya = 0;
          for (const key in piutang_lainnya) {
            if (Object.hasOwnProperty.call(piutang_lainnya, key)) {
              var elpiutang = piutang_lainnya[key];
              elpiutang.padding = 4 + parseInt(elpiutang.sub_level);
              var nilaipiutang = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaipiutang += elpiutang[el] ? parseInt(elpiutang[el]) : 0;
                  } else {
                    nilaipiutang -= elpiutang[el] ? parseInt(elpiutang[el]) : 0;
                  }
                  elpiutang[el] = elpiutang[el]
                    ? this.formatMoney(elpiutang[el])
                    : 0;
                }
              }
              if (elpiutang.sub_level === 1 || elpiutang.sub_level === "1") {
                jumlah_piutang_lainnya += nilaipiutang
                  ? parseInt(nilaipiutang.toString().split(".").join(""))
                  : 0;
              }
              elpiutang.nilai = this.formatMoney(nilaipiutang);
              elpiutang.nilai = elpiutang.nilai
                ? this.formatMoney(elpiutang.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elpiutang.sub_level == "1" || elpiutang.sub_level == 1)
              ) {
                elpiutang.color = "#FF3D3D";
              }
              data.push(elpiutang);
            }
          }

          var arryJmlPiutang = await this.countJumlahPendapatan(
            piutang_lainnya
          );

          data.push(
            {
              akun: "Jumlah Piutang Diluar Usaha",
              padding: 3,
              bold: true,
              nilai: this.formatMoney(parseInt(jumlah_piutang_lainnya)),
              ...arryJmlPiutang,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
            },
            {
              akun: "Persediaan",
              padding: 3,
              bold: true,
              nilai: "",
            }
          );

          var jumlah_persediaan = 0;
          for (const key in persediaan) {
            if (Object.hasOwnProperty.call(persediaan, key)) {
              var elpers = persediaan[key];
              elpers.padding = 4 + parseInt(elpers.sub_level);
              var nilaiops = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaiops += elpers[el] ? parseInt(elpers[el]) : 0;
                  } else {
                    nilaiops -= elpers[el] ? parseInt(elpers[el]) : 0;
                  }
                  elpers[el] = elpers[el] ? this.formatMoney(elpers[el]) : 0;
                }
              }
              if (elpers.sub_level === 1 || elpers.sub_level === "1") {
                jumlah_persediaan += nilaiops;
              }
              elpers.nilai = this.formatMoney(nilaiops);
              elpers.nilai = elpers.nilai ? this.formatMoney(elpers.nilai) : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elpers.sub_level == "1" || elpers.sub_level == 1)
              ) {
                elpers.color = "#FF3D3D";
              }
              data.push(elpers);
            }
          }

          var arrJumlahPersediaan = await this.countJumlahPendapatan(
            persediaan
          );

          data.push(
            {
              akun: "Jumlah Persediaan",
              padding: 3,
              bold: true,
              nilai: this.formatMoney(parseInt(jumlah_persediaan)),
              ...arrJumlahPersediaan,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
            },
            {
              akun: "Aset Lancar Lainnya",
              padding: 3,
              bold: true,
              nilai: "",
            }
          );

          var jumlah_aset_lancar = 0;
          for (const key in aset_lancar) {
            if (Object.hasOwnProperty.call(aset_lancar, key)) {
              var elpen = aset_lancar[key];
              elpen.padding = 4 + parseInt(elpen.sub_level);
              var nilaipen = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaipen += elpen[el] ? parseInt(elpen[el]) : 0;
                  } else {
                    nilaipen -= elpen[el] ? parseInt(elpen[el]) : 0;
                  }
                  elpen[el] = elpen[el] ? this.formatMoney(elpen[el]) : 0;
                }
              }
              if (elpen.sub_level === 1 || elpen.sub_level === "1") {
                jumlah_aset_lancar += nilaipen
                  ? parseInt(nilaipen.toString().split(".").join(""))
                  : 0;
              }
              elpen.nilai = this.formatMoney(nilaipen);
              elpen.nilai = elpen.nilai ? this.formatMoney(elpen.nilai) : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elpen.sub_level == "1" || elpen.sub_level == 1)
              ) {
                elpen.color = "#FF3D3D";
              }
              data.push(elpen);
            }
          }

          var arrJumlahLainya = await this.countJumlahPendapatan(aset_lancar);

          data.push(
            {
              akun: "Jumlah Aset Lancar Lainnya",
              padding: 3,
              nilai: this.formatMoney(parseInt(jumlah_aset_lancar)),
              bold: true,
              ...arrJumlahLainya,
            },
            {
              akun: "Jumlah Aset Lancar",
              padding: 2,
              bold: true,
              nilai: this.formatMoney(
                parseInt(jumlah_kas) +
                  parseInt(jumlah_biaya_dimuka) +
                  parseInt(jumlah_piutang_usaha) +
                  parseInt(jumlah_piutang_lainnya) +
                  parseInt(jumlah_persediaan) +
                  parseInt(jumlah_aset_lancar)
              ),
              first: this.formatMoney(
                this.sumArr([
                  arryJmlKas["first"],
                  arryJmlBiayaDimuka["first"],
                  arryJmlPiutangUsaha["first"],
                  arryJmlPiutang["first"],
                  arrJumlahPersediaan["first"],
                  arrJumlahLainya["first"],
                ])
              ),
              second: this.formatMoney(
                this.sumArr([
                  arryJmlKas["second"],
                  arryJmlBiayaDimuka["second"],
                  arryJmlPiutangUsaha["second"],
                  arryJmlPiutang["second"],
                  arrJumlahPersediaan["second"],
                  arrJumlahLainya["second"],
                ])
              ),
            },
            {
              akun: "",
              padding: "",
              nilai: "",
            },
            {
              akun: "ASET TETAP TIDAK LANCAR",
              padding: 2,
              bold: true,
            }
          );

          var jumlah_asettetapTLancar = 0;
          for (const key in aset_tetap_tidak_lancar) {
            if (Object.hasOwnProperty.call(aset_tetap_tidak_lancar, key)) {
              var elasetttdakLancar = aset_tetap_tidak_lancar[key];

              elasetttdakLancar.padding =
                3 + parseInt(elasetttdakLancar.sub_level);
              var nilaibebanltLancar = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaibebanltLancar += elasetttdakLancar[el]
                      ? parseInt(elasetttdakLancar[el])
                      : 0;
                  } else {
                    nilaibebanltLancar -= elasetttdakLancar[el]
                      ? parseInt(elasetttdakLancar[el])
                      : 0;
                  }
                  elasetttdakLancar[el] = elasetttdakLancar[el]
                    ? this.formatMoney(elasetttdakLancar[el])
                    : 0;
                }
              }
              if (
                elasetttdakLancar.sub_level === 1 ||
                elasetttdakLancar.sub_level === "1"
              ) {
                jumlah_asettetapTLancar += nilaibebanltLancar
                  ? parseInt(nilaibebanltLancar.toString().split(".").join(""))
                  : 0;
              }
              elasetttdakLancar.nilai = this.formatMoney(nilaibebanltLancar);
              elasetttdakLancar.nilai = elasetttdakLancar.nilai
                ? this.formatMoney(elasetttdakLancar.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elasetttdakLancar.sub_level == "1" ||
                  elasetttdakLancar.sub_level == 1)
              ) {
                elasetttdakLancar.color = "#FF3D3D";
              }
              data.push(elasetttdakLancar);
            }
          }

          var arrJmlAsetTetapTidakLancar = await this.countJumlahPendapatan(
            aset_tetap_tidak_lancar
          );

          data.push(
            {
              akun: "Jumlah Aset Tetap Tidak Lancar",
              padding: 2,
              bold: true,
              nilai: this.formatMoney(parseInt(jumlah_asettetapTLancar)),
              ...arrJmlAsetTetapTidakLancar,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
            },
            {
              akun: "ASET TETAP",
              padding: 2,
              bold: true,
            }
          );

          var jumlah_aset_tetap = 0;
          for (const key in aset_tetap) {
            if (Object.hasOwnProperty.call(aset_tetap, key)) {
              var elasett = aset_tetap[key];

              elasett.padding = 3 + parseInt(elasett.sub_level);
              var nilaibebanl = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaibebanl += elasett[el] ? parseInt(elasett[el]) : 0;
                  } else {
                    nilaibebanl -= elasett[el] ? parseInt(elasett[el]) : 0;
                  }
                  elasett[el] = elasett[el] ? this.formatMoney(elasett[el]) : 0;
                }
              }
              if (elasett.sub_level === 1 || elasett.sub_level === "1") {
                jumlah_aset_tetap += nilaibebanl
                  ? parseInt(nilaibebanl.toString().split(".").join(""))
                  : 0;
              }
              elasett.nilai = this.formatMoney(nilaibebanl);
              elasett.nilai = elasett.nilai
                ? this.formatMoney(elasett.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elasett.sub_level == "1" || elasett.sub_level == 1)
              ) {
                elasett.color = "#FF3D3D";
              }
              data.push(elasett);
            }
          }

          var arrJmlAsetTetap = await this.countJumlahPendapatan(aset_tetap);

          data.push(
            {
              akun: "Jumlah Aset Tetap",
              padding: 2,
              bold: true,
              nilai: this.formatMoney(parseInt(jumlah_aset_tetap)),
              ...arrJmlAsetTetap,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
            },
            {
              akun: "ASET LAINNYA",
              padding: 2,
              bold: true,
            }
          );

          var jumlah_aset_lainnya = 0;
          for (const key in aset_lainnya) {
            if (Object.hasOwnProperty.call(aset_lainnya, key)) {
              var ellainnya = aset_lainnya[key];

              ellainnya.padding = 3 + parseInt(ellainnya.sub_level);
              var nilailainnya = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilailainnya += ellainnya[el] ? parseInt(ellainnya[el]) : 0;
                  } else {
                    nilailainnya -= ellainnya[el] ? parseInt(ellainnya[el]) : 0;
                  }
                  ellainnya[el] = ellainnya[el]
                    ? this.formatMoney(ellainnya[el])
                    : 0;
                }
              }
              if (ellainnya.sub_level === 1 || ellainnya.sub_level === "1") {
                jumlah_aset_lainnya += nilailainnya
                  ? parseInt(nilailainnya.toString().split(".").join(""))
                  : 0;
              }
              ellainnya.nilai = this.formatMoney(nilailainnya);
              ellainnya.nilai = ellainnya.nilai
                ? this.formatMoney(ellainnya.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (ellainnya.sub_level == "1" || ellainnya.sub_level == 1)
              ) {
                ellainnya.color = "#FF3D3D";
              }
              data.push(ellainnya);
            }
          }

          var arrJmlAsetLainnya = await this.countJumlahPendapatan(
            aset_lainnya
          );

          data.push(
            {
              akun: "Jumlah Aset Lainya",
              padding: 2,
              bold: true,
              nilai: this.formatMoney(jumlah_aset_lainnya),
              ...arrJmlAsetLainnya,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
            },
            {
              akun: "Akumulasi Penyusutan",
              padding: 2,
              bold: true,
              nilai: "",
            }
          );

          var jumlah_akumulasi_penyusutan = 0;
          for (const key in akumulasi_penyusutan) {
            if (Object.hasOwnProperty.call(akumulasi_penyusutan, key)) {
              var ellpenyusutan = akumulasi_penyusutan[key];

              ellpenyusutan.padding = 3 + parseInt(ellpenyusutan.sub_level);
              var nilaipenyusutan = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaipenyusutan += ellpenyusutan[el]
                      ? parseInt(ellpenyusutan[el])
                      : 0;
                  } else {
                    nilaipenyusutan -= ellpenyusutan[el]
                      ? parseInt(ellpenyusutan[el])
                      : 0;
                  }
                  ellpenyusutan[el] = ellpenyusutan[el]
                    ? this.formatMoney(ellpenyusutan[el])
                    : 0;
                }
              }
              if (
                ellpenyusutan.sub_level === 1 ||
                ellpenyusutan.sub_level === "1"
              ) {
                jumlah_akumulasi_penyusutan += nilaipenyusutan
                  ? parseInt(nilaipenyusutan.toString().split(".").join(""))
                  : 0;
              }
              ellpenyusutan.nilai = this.formatMoney(nilaipenyusutan);
              ellpenyusutan.nilai = ellpenyusutan.nilai
                ? this.formatMoney(ellpenyusutan.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (ellpenyusutan.sub_level == "1" || ellpenyusutan.sub_level == 1)
              ) {
                ellpenyusutan.color = "#FF3D3D";
              }
              data.push(ellpenyusutan);
            }
          }

          var arrJmlAkumulasi = await this.countJumlahPendapatan(
            akumulasi_penyusutan
          );
          data.push(
            {
              akun: "Jumlah Akumulasi Penyusutan",
              padding: 2,
              bold: true,
              nilai: this.formatMoney(jumlah_akumulasi_penyusutan),
              ...arrJmlAkumulasi,
            },
            {
              akun: "JUMLAH ASET",
              padding: 0,
              bold: true,
              nilai: this.formatMoney(
                this.sumArr([
                  jumlah_kas,
                  jumlah_biaya_dimuka,
                  jumlah_piutang_usaha,
                  jumlah_piutang_lainnya,
                  jumlah_persediaan,
                  jumlah_aset_lancar,
                  jumlah_asettetapTLancar,
                  jumlah_aset_tetap,
                  jumlah_aset_lainnya,
                ]) - jumlah_akumulasi_penyusutan
              ),
              first: this.formatMoney(
                this.sumArr([
                  arryJmlKas.first,
                  arryJmlBiayaDimuka.first,
                  arryJmlPiutangUsaha.first,
                  arryJmlPiutang.first,
                  arrJumlahPersediaan.first,
                  arrJumlahLainya.first,
                  arrJmlAsetTetapTidakLancar.first,
                  arrJmlAsetTetap.first,
                  arrJmlAsetLainnya.first,
                ]) -
                  parseInt(arrJmlAkumulasi.first.toString().split(".").join(""))
              ),
              second: this.formatMoney(
                this.sumArr([
                  arryJmlKas.second,
                  arryJmlBiayaDimuka.second,
                  arryJmlPiutangUsaha.second,
                  arryJmlPiutang.second,
                  arrJumlahPersediaan.second,
                  arrJumlahLainya.second,
                  arrJmlAsetTetapTidakLancar.second,
                  arrJmlAsetTetap.second,
                  arrJmlAsetLainnya.second,
                ]) -
                  parseInt(
                    arrJmlAkumulasi.second.toString().split(".").join("")
                  )
              ),
            },
            {
              akun: "",
            },
            {
              akun: "KEWAJIBAN DAN EKUITAS",
              bold: true,
            },
            {
              akun: "KEWAJIBAN",
              bold: true,
              padding: 2,
            },
            {
              akun: "KEWAJIBAN JANGKA PENDEK",
              bold: true,
              padding: 3,
            },
            {
              akun: "Hutang Usaha",
              bold: true,
              padding: 4,
            }
          );

          var jumlah_hutang_usaha = 0;
          for (const key in hutang_usaha) {
            if (Object.hasOwnProperty.call(hutang_usaha, key)) {
              var ellhutang = hutang_usaha[key];

              ellhutang.padding = 5 + parseInt(ellhutang.sub_level);
              var nilaihutang = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaihutang += ellhutang[el] ? parseInt(ellhutang[el]) : 0;
                  } else {
                    nilaihutang -= ellhutang[el] ? parseInt(ellhutang[el]) : 0;
                  }
                  ellhutang[el] = ellhutang[el]
                    ? this.formatMoney(ellhutang[el])
                    : 0;
                }
              }
              if (ellhutang.sub_level === 1 || ellhutang.sub_level === "1") {
                jumlah_hutang_usaha += nilaihutang
                  ? parseInt(nilaihutang.toString().split(".").join(""))
                  : 0;
              }
              ellhutang.nilai = this.formatMoney(nilaihutang);
              ellhutang.nilai = ellhutang.nilai
                ? this.formatMoney(ellhutang.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (ellhutang.sub_level == "1" || ellhutang.sub_level == 1)
              ) {
                ellhutang.color = "#FF3D3D";
              }
              data.push(ellhutang);
            }
          }

          var arrJmlHutang = await this.countJumlahPendapatan(hutang_usaha);

          data.push(
            {
              akun: "Jumlah Hutang Usaha",
              bold: true,
              padding: 4,
              nilai: this.formatMoney(jumlah_hutang_usaha),
              ...arrJmlHutang,
            },
            {
              akun: "",
            },
            {
              akun: "Kewajiban Jangka Pendek Lainnya",
              bold: true,
              padding: 4,
            }
          );

          var jumlah_kewajiban_jangka_pendek = 0;
          for (const key in kewajiban_jangka_pendek) {
            if (Object.hasOwnProperty.call(kewajiban_jangka_pendek, key)) {
              var elpende = kewajiban_jangka_pendek[key];

              elpende.padding = 5 + parseInt(elpende.sub_level);
              var nilaipendek = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaipendek += elpende[el] ? parseInt(elpende[el]) : 0;
                  } else {
                    nilaipendek -= elpende[el] ? parseInt(elpende[el]) : 0;
                  }
                  elpende[el] = elpende[el] ? this.formatMoney(elpende[el]) : 0;
                }
              }
              if (elpende.sub_level === 1 || elpende.sub_level === "1") {
                jumlah_kewajiban_jangka_pendek += nilaipendek
                  ? parseInt(nilaipendek.toString().split(".").join(""))
                  : 0;
              }
              elpende.nilai = this.formatMoney(nilaipendek);
              elpende.nilai = elpende.nilai
                ? this.formatMoney(elpende.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elpende.sub_level == "1" || elpende.sub_level == 1)
              ) {
                elpende.color = "#FF3D3D";
              }
              data.push(elpende);
            }
          }

          var arrJmlPendek = await this.countJumlahPendapatan(
            kewajiban_jangka_pendek
          );

          data.push(
            {
              akun: "Jumlah Kewajiban Jangka Pendek Lainnya",
              bold: true,
              padding: 4,
              nilai: this.formatMoney(jumlah_kewajiban_jangka_pendek),
              ...arrJmlPendek,
            },
            {
              akun: "",
            },
            {
              akun: "Jumlah Kewajiban Jangka Pendek",
              bold: true,
              padding: 3,
              nilai: this.formatMoney(
                this.sumArr([
                  jumlah_hutang_usaha,
                  jumlah_kewajiban_jangka_pendek,
                ])
              ),
              first: this.formatMoney(
                this.sumArr([arrJmlHutang.first, arrJmlPendek.first])
              ),
              second: this.formatMoney(
                this.sumArr([arrJmlHutang.second, arrJmlPendek.second])
              ),
            },
            {
              akun: "",
            },
            {
              akun: "KEWAJIBAN JANGKA PANJANG",
              bold: true,
              padding: 3,
            }
          );

          var jumlah_kewajiban_jangka_panjang = 0;
          for (const key in kewajiban_jangka_panjang) {
            if (Object.hasOwnProperty.call(kewajiban_jangka_panjang, key)) {
              var elpanjang = kewajiban_jangka_panjang[key];

              elpanjang.padding = 5 + parseInt(elpanjang.sub_level);
              var nilaipanjang = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaipanjang += elpanjang[el] ? parseInt(elpanjang[el]) : 0;
                  } else {
                    nilaipanjang -= elpanjang[el] ? parseInt(elpanjang[el]) : 0;
                  }
                  elpanjang[el] = elpanjang[el]
                    ? this.formatMoney(elpanjang[el])
                    : 0;
                }
              }
              if (elpanjang.sub_level === 1 || elpanjang.sub_level === "1") {
                jumlah_kewajiban_jangka_panjang += nilaipanjang
                  ? parseInt(nilaipanjang.toString().split(".").join(""))
                  : 0;
              }
              elpanjang.nilai = this.formatMoney(nilaipanjang);
              elpanjang.nilai = elpanjang.nilai
                ? this.formatMoney(elpanjang.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elpanjang.sub_level == "1" || elpanjang.sub_level == 1)
              ) {
                elpanjang.color = "#FF3D3D";
              }
              data.push(elpanjang);
            }
          }

          var arrJmlPanjang = await this.countJumlahPendapatan(
            kewajiban_jangka_panjang
          );

          data.push(
            {
              akun: "Jumlah Kewajiban Jangka Panjang",
              padding: 3,
              bold: true,
              nilai: this.formatMoney(jumlah_kewajiban_jangka_panjang),
              ...arrJmlPanjang,
            },
            {
              akun: "Jumlah Kewajiban",
              padding: 2,
              bold: true,
              nilai: this.formatMoney(
                this.sumArr([
                  jumlah_kewajiban_jangka_panjang,
                  jumlah_kewajiban_jangka_pendek,
                  jumlah_hutang_usaha,
                ])
              ),
              first: this.formatMoney(
                this.sumArr([
                  arrJmlHutang.first,
                  arrJmlPendek.first,
                  arrJmlPanjang.first,
                ])
              ),
              second: this.formatMoney(
                this.sumArr([
                  arrJmlHutang.second,
                  arrJmlPendek.second,
                  arrJmlPanjang.second,
                ])
              ),
            },
            {
              akun: "",
            },
            {
              akun: "EKUITAS",
              padding: 2,
              bold: true,
              nilai: "",
            }
          );

          var jumlah_ekuitas = 0;
          for (const key in ekuitas) {
            if (Object.hasOwnProperty.call(ekuitas, key)) {
              var elekuitas = ekuitas[key];

              elekuitas.padding = 5 + parseInt(elekuitas.sub_level);
              var nilaiekuitas = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el === "first") {
                    nilaiekuitas += elekuitas[el] ? parseInt(elekuitas[el]) : 0;
                  } else {
                    nilaiekuitas -= elekuitas[el] ? parseInt(elekuitas[el]) : 0;
                  }
                  elekuitas[el] = elekuitas[el]
                    ? this.formatMoney(elekuitas[el])
                    : 0;
                }
              }
              if (elekuitas.sub_level === 1 || elekuitas.sub_level === "1") {
                jumlah_ekuitas += nilaiekuitas
                  ? parseInt(nilaiekuitas.toString().split(".").join(""))
                  : 0;
              }
              elekuitas.nilai = this.formatMoney(nilaiekuitas);
              elekuitas.nilai = elekuitas.nilai
                ? this.formatMoney(elekuitas.nilai)
                : 0;
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elekuitas.sub_level == "1" || elekuitas.sub_level == 1)
              ) {
                elekuitas.color = "#FF3D3D";
              }
              data.push(elekuitas);
            }
          }

          var arrJmlEkuitas = await this.countJumlahPendapatan(ekuitas);

          data.push(
            {
              akun: "Jumlah Ekuitas",
              bold: true,
              nilai: this.formatMoney(jumlah_ekuitas),
              padding: 2,
              ...arrJmlEkuitas,
            },
            {
              akun: "JUMLAH KEWAJIBAN DAN EKUITAS",
              bold: true,
              nilai: this.formatMoney(
                this.sumArr([
                  jumlah_kewajiban_jangka_pendek +
                    jumlah_kewajiban_jangka_panjang +
                    jumlah_hutang_usaha +
                    jumlah_ekuitas,
                ])
              ),
              first: this.formatMoney(
                this.sumArr([
                  arrJmlHutang.first,
                  arrJmlPendek.first,
                  arrJmlPanjang.first,
                  arrJmlEkuitas.first,
                ])
              ),
              second: this.formatMoney(
                this.sumArr([
                  arrJmlHutang.second,
                  arrJmlPendek.second,
                  arrJmlPanjang.second,
                  arrJmlEkuitas.second,
                ])
              ),
            }
          );

          this.dataReport = data;
          this.convertDateRange(res.first_periode, res.second_periode);
          this.isLoading = false;
          this.generatePdf();
        },
        (err) => {
          console.log(err);
        }
      );
    },

    sumArr(arr) {
      var nilai = 0;
      arr.map((element) => {
        nilai += parseInt(element.toString().split(".").join(""));
      });
      return nilai;
    },

    convertDateRange(date_start, date_end) {
      var start = moment(date_start);
      var end = moment(date_end);
      if (start.format("YYYY-MM") == end.format("YYYY-MM") && start.isValid()) {
        this.titleDate = `Per Tgl. ${start.format("DD MMM")} dan ${end.format(
          "DD MMM YYYY"
        )}`;
        this.titleDateCol = `${start.format("DD")} dan ${end.format(
          "DD MMM YYYY"
        )}`;
      } else if (
        start.format("YYYY") != end.format("YYYY") &&
        start.isValid()
      ) {
        this.titleDate = `Per Tgl. ${start.format("DD MMM")} dan ${end.format(
          "DD MMM YYYY"
        )}`;
        this.titleDateCol = `${start.format("DD MMM")} dan ${end.format(
          "DD MMM YYYY"
        )}`;
      } else {
        this.titleDate = `Per Tgl. ${start.format(
          "DD MMM YYYY"
        )} dan ${end.format("DD MMM YYYY")}`;
        this.titleDateCol = `${start.format("DD MMM YYYY")} dan ${end.format(
          "DD MMM YYYY"
        )}`;
      }
    },

    summaryArray(obj, type = "") {
      var nilai = 0;
      var i = 0;
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const element = obj[key];
          if (type == "beban") {
            if (i == 0) {
              nilai = parseInt(element.toString().split(".").join(""));
            } else if (
              parseInt(element.toString().split(".").join("")) >= 0 &&
              nilai != 0
            ) {
              nilai -= parseInt(element.toString().split(".").join(""));
            } else {
              nilai += parseInt(element.toString().split(".").join(""));
            }
          } else {
            if (i == 0) {
              nilai = parseInt(element.toString().split(".").join(""));
            } else if (
              parseInt(element.toString().split(".").join("")) >= 0 &&
              nilai != 0
            ) {
              nilai -= parseInt(element.toString().split(".").join(""));
            } else {
              nilai += parseInt(element.toString().split(".").join(""));
            }
          }
          i++;
        }
      }
      return nilai;
    },

    countJumlahPendapatan(data) {
      var object = {
        first: 0,
        second: 0,
      };

      for (const i in data) {
        const element = data[i];
        for (const key in element) {
          if (
            Object.hasOwnProperty.call(element, key) &&
            key != "nilai" &&
            key != "akun" &&
            key != "padding"
          ) {
            const el = element[key];
            if (element.sub_level === 1 || element.sub_level === "1") {
              object[key] += el
                ? parseInt(el.toString().split(".").join(""))
                : 0;
            }
          }
        }
      }

      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          object[key] = object[key] ? this.formatMoney(object[key]) : 0;
        }
      }

      return object;
    },

    setHide() {
      this.showModalRangeDouble = false;
    },

    updateData(event) {
      this.parameterReport = event;
      this.getData();
    },

    getDivisi() {
      get_ListDivisi(
        {
          id_company: cksClient().get("_account").id_company,
        },
        (res) => {
          this.optionDepartement = res.list;
        }
      );
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#6BACCC"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoTwoArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 2);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      // const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const secondArray = wordsArray.slice(arraySize);

      return [firstArray, secondArray];
    },

    async generatePdf() {
      const doc = new jsPDF("l", "mm", "a4");
      var header = "/img/bg-header.png";
      doc.addImage(header, "png", 298, -35, 300, 2500 / 72, null, null, 180);
      var logo = await this.convertImgToBase64(
        DATA.COMPANY(this.dataCompany.logo)
      );
      doc.addImage(logo, "png", 13, 6, 20, 20, null, null, 0);

      doc.setFont("Poppins-Bold").setFontSize(13);
      doc.setTextColor(255, 255, 255);
      doc.text(37, 15, `${this.dataCompany.nama}`, {
        align: "left",
      });
      doc.setFont("Poppins-Regular").setFontSize(10);
      doc.setTextColor(255, 255, 255);
      doc.text(
        37,
        22,
        `${
          this.dataCompany.divisi
            ? this.dataCompany.divisi
            : this.dataCompany.email
        }`,
        {
          align: "left",
        }
      );

      doc.setFontSize(9);
      doc.setFont("Poppins-Regular");
      doc.splitTextToSize(this.dataCompany.alamat, 10);

      const [array1, array2] = this.splitTextIntoTwoArrays(
        this.dataCompany.alamat,
        2
      );

      doc.text(288, 10, array1.join(" "), {
        align: "right",
      });
      doc.text(288, 16, array2.join(" "), {
        align: "right",
      });
      doc.text(288, 22, this.dataCompany.no_telp, {
        align: "right",
      });
      doc.text(288, 28, this.dataCompany.divisi ? this.dataCompany.email : "", {
        align: "right",
      });
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      // doc.text(145, 45, this.namaReport, "center");
      doc.setFontSize(16);
      doc.text(145, 45, `Neraca (Perbandingan Periode)`, {
        align: "center",
      });
      doc.setProperties({
        title: `Neraca (Perbandingan Periode)`,
      });
      doc.setFontSize(10);
      doc.text(145, 53, this.titleDate, "center");
      var blankTable = {
        columns: [
          {
            title: "",
            dataKey: "id",
          },
        ],
        rows: [
          {
            id: "",
          },
        ],
      };
      doc.autoTable(blankTable.columns, blankTable.rows, {
        margin: {
          top: 45,
          bottom: 80,
        },
        theme: "plain",
      });
      var tableData = {
        columns: this.dataColumn,
        rows: this.dataReport,
      };
      doc.autoTable(tableData.columns, tableData.rows, {
        showHead: "everyPage",
        didParseCell: function (data) {
          var padding = parseInt(data.row.raw.padding);
          if (data.column.index === 0 && parseInt(padding)) {
            data.cell.styles.cellPadding = {
              left: 3 * parseInt(padding),
            };
          }

          if (data.column.index === 0 && data.row.raw.bold) {
            data.cell.styles.fontStyle = "bold";
          }

          if (data.row.section === "head") {
            data.cell.styles.textColor = "#324C8F";
            // data.cell.styles.halign = "center";
            if (data.column.dataKey !== "akun") {
              data.cell.styles.halign = "right";
            }
          }
          if (data.row.raw.color) {
            data.cell.styles.textColor = data.row.raw.color;
          }
        },

        columnStyles: {
          0: {},
          1: {
            halign: "right",
          },
          2: {
            halign: "right",
          },
          3: {
            halign: "right",
          },
          4: {
            halign: "right",
          },
        },
        margin: {
          top: 10,
          bottom: 60,
        },
        theme: "plain",
      });

      // PAGE NUMBERING
      // Add Page number at bottom-right
      // Get the number of pages
      const pageCount = doc.internal.getNumberOfPages();
      // var width = doc.internal.pageSize.getWidth();
      // var height = doc.internal.pageSize.getHeight();

      // For each page, print the page number and the total pages
      for (var i = 1; i <= pageCount; i++) {
        // Go to page i
        doc.setPage(i);

        //Print Page 1 of 4 for example

        var img = new Image();
        img.src = "img/line.png";
        doc.addImage(img, "png", 270, 160, 257, 1 / 72, null, null, 180);

        doc.text(105, 257, "Finanta Accounting System Report", "center");

        doc.text(
          "Page " + String(i) + " of " + String(pageCount),
          290 - 20,
          210 - 30,
          null,
          null,
          "right"
        );

        doc.text(
          `Tercetak pada ${moment().format("DD MMMM YYYY - HH:mm")}`,
          205 - 192,
          210 - 30,
          null,
          null,
          "left"
        );
      }

      setTimeout(() => {
        var iframe = document.getElementById("pdfFrame");
        iframe.src = doc.output("bloburi", {
          filename: `Neraca ${this.namaReport} (Perbandingan Periode).pdf`,
        });
      }, 500);
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(payroll) {
      const getPayroll = payroll;

      if (getPayroll == "bonus") {
        this.$router.push("/data-payroll/detail-bonus");
      }
      if (getPayroll == "gaji") {
        this.$router.push("/data-payroll/detail-gaji");
      }
      if (getPayroll == "thr") {
        this.$router.push("/data-payroll/detail-thr");
      }

      $('[data-toggle="tooltip"]').tooltip("hide");
    },
  },
};
</script>

<style scoped>
.card-table {
  margin-top: 0px !important;
}

.btn-gaji {
  background-color: #68caf8;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-bonus {
  background-color: #f8ce3a;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-thr {
  background-color: #ff5252;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.dropdown-menu {
  background: transparent !important;
  box-shadow: none !important;
  width: 102px !important;
  min-width: unset;
}

.dropdown-item:nth-child(2) {
  margin-top: 6px;
}

.dropdown-item:nth-child(3) {
  margin-top: 6px;
}

iframe {
  z-index: 1000;
  width: 100%;
  height: 800px;
}
</style>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>